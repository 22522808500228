<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.SAVE')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptEditOfferOptions"
      :is-valid="formValid">
       <div>
        <div>
              <vs-textarea height="100px" counter="2500" :label="$t('OFFERS.ADD_OFFER.DESCRIPTION')" v-model="offer.description" />
              <div class="default-input d-flex align-items-center mb-3 mt-2">
                  <DatePicker v-if="!offer.useDeliveryText" ref="pickerDeliveryDate"  Type="date" class="float-left" :Label="$t('INVOICE.ADD_INVOICE.DELIVERY_DATE')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('INVOICE.ADD_INVOICE.TITLE.DELIVERY_DATE')" v-model="offer.deliverydate"></DatePicker>
                  <vs-input v-if="offer.useDeliveryText" :disabled="!offer.useDeliveryText" :label="$t('INVOICE.ADD_INVOICE.LABEL.DELIVERY_TEXT')"  class="float-left mr-2 inputx" :placeholder="$t('INVOICE.ADD_INVOICE.LABEL.PLACEHOLDER.DELIVERY_TEXT')"  v-model="offer.deliveryText"/>
                  <vs-checkbox  v-model="offer.useDeliveryText">{{$t('INVOICE.ADD_INVOICE.LABEL.USE_DELIVERY_TEXT')}}</vs-checkbox>
              </div>
              <div class="default-input clearfix align-items-center mb-3">
                <div class="float-left" >
                  <vs-select  :label="$t('OFFERS.ADD_OFFER.LABEL.DELIVERYCOND')" v-model="offer.deliverycondition_fk">
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in deliveryconditions" />
                  </vs-select>
                  <div class="clearfix">
                    <vs-input-number  v-model="offer.deliverycondition_pricecustom" class="inputx float-left" :label="$t('OFFERS.ADD_OFFER.CUSTOM_PRICE')"/>  
                  </div>
                </div>
                <div class="float-left ml-3" >
                  <vs-select   :label="$t('OFFERS.ADD_OFFER.LABEL.PAYMENTCOND')" v-model="offer.paymentcondition_fk">
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in paymentconditions" />
                  </vs-select>  
                </div>
                <div class="float-left ml-3" >
                  <vs-select   :label="$t('OFFERS.ADD_OFFER.LABEL.SPECIALDISCOUNT')" v-model="offer.special_discount_fk">
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in specialdiscounts" />
                  </vs-select>      
                </div>
              </div>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';
import DatePicker from '../DatePicker.vue';

export default {
  name: "EditOfferOptions",
  props:[],
  components:{
    DatePicker
  },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('OFFERS.ADD_OFFER.TITLE'),
      offer:null
    }
  }, 
  mounted () {
    this.$store.dispatch('numbergroup/getNumbergroupsByCompany',0,{ root: true });
    this.ClearForm();
    
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      ShowDlg(){
        this.ClearForm();   
        this.dlgActive=true;
      },
      closed(){
        this.ClearForm();
        this.$emit('Canceld');
      },
      InitDlgData(data){
        this.ClearForm();
        if(this.IsNotNull(data))
        {
          this.offer = data;

          this.popupTitle = this.$t('OFFERS.ADD_OFFER.TITLE.EDIT') + ' ('+this.offer.offer_nr+')';

          this.dlgActive=true;
        }
      },    
      accepted(){

          this.$emit('Accepted');
          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
        this.$emit('Canceld');
      },
      ClearForm(){ 
      }
  },
  computed:{
    formValid(){
      
      return true;
    },
    deliveryconditions(){    
      return this.GetListOfDeliveryConditionsForSelect(this.offer.country_fk,this.offer.currency_fk);
    },
    paymentconditions(){    
      return this.GetListOfPaymentConditionsForSelect(this.offer.country_fk,this.offer.currency_fk);
    },
    specialdiscounts()
    {
      return this.GetListOfSpecialDiscountsForSelect();
    },
  },
  watch:{

  }
};
</script>
<style>
div#promptEditOfferOptions > div.vs-dialog{
  max-width: 800px !important;
}
div#promptEditOfferOptions > div.vs-dialog > 
div.vs-dialog-text > div > div > 
div.vs-tabs > div.con-slot-tabs > div.vs-tabs--content{
min-height: 450px;
}

</style>